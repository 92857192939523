<template>
  <div id="listing-header-point" class="layout-container">
    <div id="fixed-parent" style="width: 100%;">
      <el-row id="listing-header" :gutter="24" style="padding: 10px;" justify="center" align="middle" class="no-margin">
        <el-col :xs="9" :sm="9" :md="9" :lg="9" :xl="9">
          <img src="" alt="">
        </el-col>
        <el-col :span="5" class="center-text" style="font-size: 18px; color: #0aa699;">
          เริ่มต้น
        </el-col>
        <el-col :span="5" class="center-text" style="font-size: 18px; color: #009df8;">
          รุ่นธรรมดา
        </el-col>
        <el-col :span="5" class="center-text" style="font-size: 18px; color: rgb(31, 46, 255);">
          รุ่น Pro
        </el-col>
      </el-row>
    </div>
    <el-row :gutter="24" style="padding: 10px;" justify="center" align="middle" class="no-margin">
      <el-col :span="9" style="color: Black; font-size: 36px; font-weight: 300;">
        Key<br>Features
      </el-col>
      <el-col :span="5" class="center-text">
        <img src="@/assets/whale_starter.jpg" alt="" style="width: 100%;">
      </el-col>
      <el-col :span="5" class="center-text">
        <img src="@/assets/whale_casual.jpg" alt="" style="width: 100%;">
      </el-col>
      <el-col :span="5" class="center-text">
        <img src="@/assets/whale_pro.jpg" alt="" style="width: 100%;">
      </el-col>
    </el-row>

    <span v-for="(element, idx) in listing" :key="idx">
      <el-row :gutter="24" style="padding: 20px 20px 5px 20px;">
        <el-col :span="9" class="detail-header-text">{{element.title}}</el-col>
      </el-row>
      <el-row
        :gutter="24"
        style="padding: 0px 20px; display: flex; align-items: flex-end;"
        justify="center"
        align="middle"
        class="no-margin"
        v-for="(item, index) in element.list" :key="index"
      >
        <el-col :span="9" class="a-detail-title" :style="!item.isNotBorder ? '' : 'border: none;'">
          {{item.title}}
        </el-col>
        <template v-if="item.isHtml" :style="!item.isNotBorder ? '' : 'border: none;'">
          <el-col :span="15" style="" class="center-text" v-html="item.html"></el-col>
        </template>
        <template v-else>
          <el-col :span="5" class="center-text">
            <i :class="item.got1 ? 'el-icon-check a-detail-symbol' : 'el-icon-minus a-detail-symbol'"></i>
          </el-col>
          <el-col :span="5" class="center-text">
            <i :class="item.got2 ? 'el-icon-check a-detail-symbol' : 'el-icon-minus a-detail-symbol'"></i>
          </el-col>
          <el-col :span="5" class="center-text">
            <i :class="item.got3 ? 'el-icon-check a-detail-symbol' : 'el-icon-minus a-detail-symbol'"></i>
          </el-col>
        </template>
      </el-row>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Package-Detail',
  data () {
    return {
      listing: [
        {title: 'ระบบไลฟ์สด (Facebook/IG Live)',
          list: [
            {title: 'เก็บยอด CF ทุกรายการ ไม่มีตกหล่น', got1: false, got2: false, got3: true},
            {title: 'ลูกค้าเลือกสรุปยอดได้เอง', got1: false, got2: false, got3: true},
            {title: 'เชื่อมกับระบบการเงินอัตโนมัติ',got1: false, got2: false, got3: true},
            {title: 'รายงานสถิติการเงิน', got1: false, got2: false, got3: true},
            {title: 'เชื่อมกับระบบจัดการร้านค้า Shipnity', got1: false, got2: false, got3: true},
          ]
        },

        {
          title: 'เชื่อม Chat กับ Facebook พร้อมจัดการหลังบ้านได้',
          list: [
            {title: 'เปิดออเดอร์ใน Inbox', got1: false, got2: false, got3: true},
            {title: 'แบ่งหมวดออเดอร์ใน Inbox เช่น ทั้งหมด, สั่งจอง, รอบิล, รอแพ็ค', got1: false, got2: false, got3: true},
            {title: 'ส่งบิลใน Inbox', got1: false, got2: false, got3: true},
            {title: 'ส่งข้อความยืนยันเมื่อลูกค้าแจ้งโอนเงิน', got1: false, got2: false, got3: true},
            {title: 'ตั้งค่าข้อความใช้บ่อย', got1: false, got2: false, got3: true},
            {title: 'แจ้งเตือนเมื่อลูกค้าทักเข้ามา', got1: false, got2: false, got3: true},
            {title: 'อัพโหลดสลิปโดยตรงจากหน้าแชท', got1: false, got2: false, got3: true},
            {title: 'สรุป Tag ของลูกค้า', got1: false, got2: false, got3: true},
            {title: 'เชื่อม Facebook ได้หลาย Page', got1: false, got2: false, got3: true}
          ]
        },
        {
          title: 'ระบบออเดอร์',
          list: [
            {title: 'ลูกค้าแจ้งโอนเงิน พร้อมติดตามสถานะการซื้อสินค้าผ่านลิ้งค์ได้ด้วยตัวเอง', got1: true, got2: true, got3: true},
            {title: 'สถานะชำระเงิน ส่งสินค้าของลูกค้า', got1: true, got2: true, got3: true},
            {title: 'บันทึกชื่อ ที่อยู่ เบอร์โทร หมายเลขผู้เสียภาษีของลูกค้า', got1: true, got2: true, got3: true},
            {title: 'ปรินท์ใบปะกล่อง/ใบ Dropoff/ใบกำกับภาษี ', got1: true, got2: true, got3: true},
            {title: 'ลงค่าใช้จ่ายแบบแบ่งหมวดหมู่', got1:false, got2: false, got3: true},
            {title: 'คำนวนส่วนลดให้ลูกค้าแต่ละกลุ่มโดยอัตโนมัติ', got1: false, got2: false, got3: true},
            {title: 'ปรับแต่งใบเสร็จได้ตามใจ', got1: false, got2: false, got3: true},
            {title: 'ตรวจเช็คที่อยู่อัตโนมัติเพื่อป้องกันลูกค้ากรอกที่อยู่ผิด', got1: false, got2: false, got3: true},
            {title: 'สามารถลงค่าใช้จ่ายต่างๆ นอกเหนือจากต้นทุนสินค้า', got1: true, got2: true, got3: true},
            {title: 'เช็คสลิปโอนอัตโนมัติ', got1: true, got2: true, got3: true},
            {title: 'รองรับการพรีออเดอร์', got1: false, got2: false, got3: true},
            {title: 'รองรัับออเดอร์แบบเก็บเงินปลายทาง (COD)', got1: true, got2: true, got3: true},
         ]
        },
        {
          title: 'ระบบสต็อกสินค้า',
          list: [
            {title: 'ไม่จำกัดจำนวนสินค้า', got1: 'true', got2: 'true', got3: 'true'},
            {title: 'สร้าง Barcode สินค้า', got1: false, got2: false, got3: true},
            {title: 'เพิ่มสต็อกด้วย Excel', got1: true, got2: true, got3: true},
            {title: 'สินค้าหลักและสินค้าย่อย', got1: false, got2: false, got3: true},
            {title: 'แสดงต้นทุนสินค้ารวมและต่อชิ้น', got1: true, got2: true, got3: true},
            {title: 'กำหนดอายุใบสั่งซื้อ', got1: false, got2: false, got3: true},
            {title: 'จัด Set สินค้าแบบโปรโมชั่น', got1: false, got2: false, got3: true},
            {title: 'แสดงยอดมูลค่า/ต้นทุนของสต็อกรวม', got1: true, got2: true, got3: true},
            {title: 'แบ่งหมวดหมู่สินค้าพร้อม', got1: true, got2: true, got3: true},
            {title: 'ลิ้งค์สั่งซื้อสินค้าสำหรับลูกค้า', got1: true, got2: true, got3: true},
            {title: 'รองรับสต็อกหลายคลังสินค้า', got1: true, got2: true, got3: true},
            {title: 'แจ้งเตือนสินค้าใกล้หมด', got1: true, got2: true, got3: true},
            {title: 'แสดงประวัติการเพิ่มสินค้า', got1: true, got2: true, got3: true},
            {title: 'บันทึกข้อมูล Supplier', got1: false, got2: false, got3: true},
            {title: 'ระบบคืนสินค้าชำรุด/สินค้าเสียหาย', got1: false, got2: false, got3: true},
            {title: 'Import/Export รายการสินค้า หมวดหมู่สินค้า', got1: false, got2: false, got3: true},
            {title: 'ประวัติสต็อกย้อนหลัง', got1: true, got2: true, got3: true},
            
          ]
        },
        {
          title: 'ระบบแพ็คสินค้า - จัดส่ง',
          list: [
            {title: 'ตั้งราคาค่าส่งตามราคา จำนวน น้ำหนัก ', got1: false, got2: false, got3: true},
            {title: 'คำนวนค่าส่งอัตโนมัติ แยกตามขนส่ง', got1: true, got2: true, got3: true},
            {title: 'ส่งสินค้ากับ Shipnity ได้เรทราคาถูกกว่าเดิม', got1: true, got2: true, got3: true},
            {title: 'เชื่อมต่อกับขนส่งชั้นนำ เช่น J&T Express, Kerry, Flash Express, ไปรษณีย์ไทย', got1: true, got2: true, got3: true},
            {title: 'แจ้งหมายเลขพัสดุอัตโนมัติผ่าน SMS', got1: true, got2: true, got3: true},
          ]
        },
        {
          title: 'ระบบรายงานสถิติและการเงิน',
          list: [
            {title: 'รายงานสถิติและการเงิน', got1: true, got2: true, got3: true},
            {title: 'Export รายงานเป็นไฟล์ Excel', got1: false, got2: false, got3: true},
            {title: 'รายงาน Cash Flow', got1: false, got2: false, got3: true},
            {title: 'รายงานลูกค้า', got1: true, got2: true, got3: true},
            {title: 'รายงานสินค้าชำรุด/สินค้าคืนกลับ', got1: false, got2: false, got3: true},
            {title: 'รายงานสต็อก', got1: true, got2: true, got3: true},
            {title: 'รายงานยอดขาย',got1: true,got2: true,got3:true},
            {title: 'รายงานกำไร',got1: true,got2: true,got3:true},
            {title: 'รายงานยอดขายของแอดมิน',got1: false,got2: false,got3:true},
            {title: 'รายงานยอดขายหน้าร้าน (POS)',got1: false,got2: false,got3:true},
            {title: 'รายงานเป็นรายวัน เดือน ปี',got1: true,got2: true,got3:true},
            {title: 'รายงานสรุปกำไรจากตัวแทนจำหน่าย',got1: false, got2: false, got3: true},
          ]  
        },

        {
          title: 'ระบบบริหารลูกค้า (CRM)',
          list: [
            {title: 'ตั้งหมวดหมู่ลูกค้าได้ เช่น ลูกค้าประจำ ลูกค้าขายส่ง', got1: false, got2: false, got3: true},
            {title: 'Import/Export ประวัติหรือข้อมูลลูกค้า', got1: false, got2: false, got3: true},
            {title: 'แสดงประวัติลูกค้าที่เคยซื้อทั้งหมด', got1: true, got2: true, got3: true},
          ]
        },

        {
          title: 'ระบบตัวแทนจำหน่าย',
          list: [
            {title: 'ตัวแทนสั่งสินค้าได้ด้วยตัวเอง', got1: false, got2: false, got3: true},
            {title: 'กำหนดขั้นหรือตำแหน่งของตัวแทน', got1: false, got2: false, got3: true},
            {title: 'รายงานยอดขายของตัวแทน',got1:false,got2:false,got3:true},
            {title: 'ระบบสะสมแต้ม แลกของรางวัล',got1:false,got2:false,got3:true},     
          ]
        },
        
        {
          title: 'ระบบแอดมิน',
          list: [
            {title: 'ตั้งค่าตำแหน่งของแอดมินได้ เช่น ฝ่ายสต็อก ฝ่ายแพ็คสินค้า ฝ่ายเซลส์', got1: false, got2: false, got3: true},
            {title: 'กำหนดสิทธิ์ของแอดมิน เช่น ไม่ให้แอดมินแก้ไขคลังสินค้า', got1: false, got2: false, got3: true},
            {title: 'รายงานยอดขายของแอดมินแต่ละคน', got1: false, got2: false, got3: true},
            {title: 'รายงานการทำงานของแอดมิน', got1: false, got2: false, got3: true},
          ]
        },

{
  title:'ระบบ POS ขายหน้าร้าน',
  list: [
    {title:'คำนวณการรับเงิน/ทอนเงินอัตโนมัติ',got1:false,got2:false,got3:true},
    {title:'สแกน Barcode สินค้า',got1:false,got2:false,got3:true},
    {title:'รองรับการชำระเงินด้วยเงินสดและบัตรเครดิต',got1:false,got2:false,got3:true},
    {title:'รายงานยอดขายหน้าร้าน',got1:false,got2:false,got3:false}   
  ]
},
      
         {
          title: 'ระบบ Barcode',
          list: [
            {title: 'ระบบสร้างและพิมพ์ Barcode', got1: false, got2: false, got3: true},
            {title: 'สแกนเช็ครายการสินค้า', got1: false, got2: false, got3: true},
            {title: 'สแกนขายหน้าร้าน', got1: false, got2: false, got3: true},
            {title: 'สแกนตัดสต็อก', got1: false, got2: false, got3: true},
          ]
        },

        {
          title: 'สะดวกลูกค้า',
          list: [
            {title: 'รับชำระเงินผ่านบัตรเครดิต', got1: true, got2: true, got3: true},
            {title: 'รับชำระเงินผ่านธนาคาร', got1: true, got2: true, got3: true},
            {title: 'รับชำระเงินด้วย QR Code', got1: true, got2: true, got3: true},
            {title: 'บริการเก็บเงินปลายทาง (COD)', got1: true, got2: true, got3: true},
          ]
        },
        {
          title: 'สะดวกแม่ค้า',
          list: [
            {title: 'เชื่อมต่อช่องทางการขาย เช่น Shopee, Lazada, Wordpress', got1: false, got2: false, got3: true},
            {title: 'เชื่อมต่อระบบ Fulfillment', got1: false, got2: false, got3: true},
            {title: 'บริการเว็บไซต์สำเร็จรูป', got1: false, got2: false, got3: true },
            {title: 'SMS แจ้งเลขพัสดุอัตโนมัติเมื่อออเดอร์เข้าระบบขนส่ง', isHtml: true, html: '<div class="html-listing-detail" style="border-bottom: 1px #ccc solid; display: inline-block;"><span>1.5 บาท/70ตัวอักษร</span><span class="a-red-chip">ถูกที่สุด</span></div>'},
          ]
        },
        {
          title: 'การช่วยเหลือ',
          list: [
            {title: 'การช่วยเหลือทางเทคนิค', isHtml: true, html: '<div style="border-bottom: 1px #ccc solid; display: inline-block;"><span style="padding-bottom: 7px; line-height: 40px;">ติดต่อ Customer Service ได้ทาง Line, Facebook และ Call Center ทุกแพ็คเกจ</span></div>'},
          ]
        }
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.onresize);
    this.onresize()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onresize () {
      var parent = document.getElementById("fixed-parent");
      var { style } = document.getElementById('listing-header');
      style.width = `${parent.clientWidth}px`;
    },
    handleScroll () {
      var point = document.getElementById("listing-header-point");
      var item = document.getElementById('listing-header');
      if (window.pageYOffset > point.offsetTop) {
        item.classList.add("sticky");
      } else if (window.pageYOffset < point.offsetTop) {
        item.classList.remove("sticky");
      }
    }
  }
};
</script>

<style lang="scss">
  .layout-container {
    position: relative;
    background-color: white;
    margin: 20px 0 0 0;
    padding: 24px 10px 24px 10px;
  }

  .no-margin {
    margin: 0!important;
  }

  .center-text {
    text-align: center;
  }

  .detail-header-text {
    font-size: 18px;
    color: black;
  }

  .a-detail-title {
    padding: 7px 0;
    border-bottom: 1px #ccc solid;
    font-size: 16px;
    font-weight: 200;
    color: black;
  }

  .a-detail-symbol {
    border-bottom: 1px #ccc solid;
    width: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #00d6cb;
    padding: 7px 0;
  }

  .a-detail-symbol-not {
    border-bottom: 1px #ccc solid;
    width: 60px;
    font-size: 24px;
    font-weight: bold;
    color: #d84a68;
    padding: 7px 0;
  }

  .a-red-chip {
    background-color: red;
    color: white;
    border-radius: 15px;
    font-size: 12px;
    padding: 2px 15px 2px 15px;
    margin-left: 24px;
    line-height: 30px;
  }

  .a-red-chip-no-padding {
    background-color: red;
    color: white;
    border-radius: 15px;
    font-size: 12px;
    padding: 2px 15px 2px 15px;
    line-height: 30px;
  }

  .sticky {
    position: fixed;
    background-color: white;
    z-index: 1;
    width: inherit;
    top: 0;
  }

  .html-listing-detail {
    border-bottom: 1px #ccc solid;
    display: inline-block;
    width: 477px;
  }

  @media only screen and (max-width: 930px) {
    .html-listing-detail {
      width: 100%;
    }
  }
</style>
