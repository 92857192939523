<template>
  <div class="container">
    <h3 style="color: #337ab7; font-weight: bold;">
      <a @click="back()" style="cursor: pointer;">Shipnity</a>
    </h3>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    methods: {
      back() {
        let urlParams = new URLSearchParams(window.location.search);
        let from = urlParams.get('from');

        if (from == "pro") {
          window.location.href = "https://www.shipnity.com"
        } else {
          window.location.href = "https://classic.shipnity.com"
        }
      }
    }
  };
</script>

<style media="screen">
.container {
  padding-right: 21px;
  padding-left: 21px;
  margin-right: 21px;
  margin-left: 21px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
</style>
