<template>
  <div class="layout-container" id="top-of-content">
    <el-row type="flex" :gutter="24" justify="center" align="middle" class="container-res">
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
        <img v-if="isPro" src="@/assets/uncle.png" alt="" style="style='max-height: 80%; max-width: 80%; object-fit: contain'">
        <img v-else src="@/assets/uncle_student.png" alt="" style="style='max-height: 80%; max-width: 80%; object-fit: contain'">
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" style="min-height: 100px;">
        <div v-for="(item, index) in !isPro ? descBasic : descPro" :key="index">
          <span v-html="item"></span>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" style="min-height: 100px; display: contents;">
        <el-button type="primary" :class="!isPro ? 'gredient-button' : 'gredient-button-pro'" v-on:click='register(isPro)'>{{!isPro ? 'สมัครระบบฟรี ตลอดไป' : 'สมัครวันนี้ ใช้งานฟรี 14 วัน'}}</el-button>
      </el-col>
    </el-row>
    <!-- Package list -->
    <el-row justify="space-around" align="middle" type="flex" :class="isPro ? 'hidden-sm-and-down' : ''">
      <el-col style="padding: 10px" :xs="isPro ? 24 : 24" :sm="12" :md="isPro ? 5 : 6" :lg="isPro ? 5 : 6" :xl="isPro ? 5 : 6" v-for="(item, idx) in isPro ? pro : basic" :key="idx">
        <div class="package-container">
          <div style="font-size: 17px; color: black;">
            {{item.title}}
          </div>
          <div style="font-size: 17px; color: black;">
            {{item.price}} ฿
          </div>
          <div style="margin-top: 10px;">
            <span :class="!isPro ? 'round-price-pack' : 'round-price-pack pro'" v-for="(pitem, ind) in item.pricePack" :key="ind">{{pitem}}</span>
          </div>
          <div style="height: 20px; padding: 28px 0 15px 0;">
            <button v-if="item.isBuyable" :class="!isPro ? 'package-buyable-btn' : 'package-buyable-btn-pro'" v-on:click="redirect(item)">
              Buy
            </button>
            <button v-else :class="!isPro ? 'package-buyable-btn' : 'package-buyable-btn-pro'" onclick="window.location.href = 'https://www.shipnity.com/users/sign_up'">
              สมัครเลย
            </button>
          </div>
          <div v-html="dItem" v-for="(dItem, index) in item.detail" :key="index" :class="!isPro ? 'package-detail-list' : 'package-detail-list pro'">
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- Package List for Mobile -->
    <el-row justify="space-around" align="middle" :class="isPro ? 'hidden-md-and-up' : 'hidden'">
      <el-col style="padding: 10px" :xs="isPro ? 24 : 24" :sm="12" :md="isPro ? 5 : 6" :lg="isPro ? 5 : 6" :xl="isPro ? 5 : 6" v-for="(item, idx) in isPro ? pro : basic" :key="idx">
        <div class="package-container">
          <div style="font-size: 17px; color: black;">
            {{item.title}}
          </div>
          <div style="font-size: 17px; color: black;">
            {{item.price}} ฿
          </div>
          <div style="margin-top: 10px;">
            <span :class="!isPro ? 'round-price-pack' : 'round-price-pack pro'" v-for="(pitem, ind) in item.pricePack" :key="ind">{{pitem}}</span>
          </div>
          <div style="height: 20px; padding: 28px 0 15px 0;">
            <button v-if="item.isBuyable" :class="!isPro ? 'package-buyable-btn' : 'package-buyable-btn-pro'" v-on:click="redirect(item)">
              Buy
            </button>
            <button v-else :class="!isPro ? 'package-buyable-btn' : 'package-buyable-btn-pro'" onclick="window.location.href = 'https://www.shipnity.com/users/sign_up'">
              สมัครเลย
            </button>
          </div>
          <div v-html="dItem" v-for="(dItem, index) in item.detail" :key="index" :class="!isPro ? 'package-detail-list' : 'package-detail-list pro'">
          </div>
        </div>
      </el-col>
    </el-row>
    <div>
      <center>
        <p style="color: #F14D4D">ราคานี้ยังไม่รวมภาษีมูลค่าเพิ่ม 7%</p>
      </center>
    </div>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/display.css';
export default {
  name: 'Title',
  data () {
    return {
      isPro: true,
      descBasic: [
        '<b class="package-title-text">สมัครวันนี้ ฟรีทันที 50 ออร์เดอร์</b>',
        '<span>- ใช้ฟรีตลอดไป</span>',
        '<span>- ระบบใช้งานง่ายที่สุด</span>',
        '<span>- มีครบทุกฟังก์ชันพื้นฐานที่จำเป็น</span>'
      ],
      descPro: [
        '<span class="package-title-head-pro">Features</span>',
        '- เชื่อมต่อแชทกับ Facebook, IG และ LINE OA',
        '- เชื่อมต่อระบบกับ Marketplace ทั้ง Shopee, Lazada, Tiktok และ Line Shopping',
        '- ระบบจัดการ Live',
        '- Website สำเร็จรูป',
        '- สร้างใบเสร็จ/ใบปะกล่องได้เองตามต้องการ',
        '- ระบบตัวแทนจำหน่าย',
        '- ระบบ POS ขายของหน้าร้าน',
        '- ต้นทุนสินค้าเปลี่ยนแปลงตามล็อต',
        '- เก็บข้อมูลการซื้อขายจาก Supplier',
        '- ระบบยิงบาร์โค้ดสินค้าเพื่อเช็คของก่อนแพ็คส่ง ป้องกันการตกหล่นหรือหยิบผิด',
        '- ระบบคืนสินค้า/บันทึกสินค้าชำรุด',
        '- สร้างรายงานเองได้ตามต้องการ',
      ],
      basic: [
        {
          title: 'เริ่มต้น',
          price: 0,
          isBuyable: false,
          pricePack: [
            'ฟรีตลอดไป'
          ],
          detail: [
            '50 ออเดอร์/เดือน',
            '1 User',
            '1 คลังสินค้า',
            '0 ตัวแทนจำหน่าย',
            'ไม่จำกัดรายการสินค้า'
          ],
          url: "https://classic.shipnity.com/users/sign_up"
        },
        {
          title: 'ร้านขนาดกลาง',
          price: "450",
          isBuyable: true,
          pricePack: [
            '1,200 ฿ / 3 เดือน',
            '4,700 ฿ / 1 ปี',
          ],
          detail: [
            '150 ออเดอร์/เดือน',
            '3 Admin',
            '1 คลังสินค้า',
            '4 ตัวแทนจำหน่าย',
            'ไม่จำกัดรายการสินค้า'
          ],
          url: "https://classic.shipnity.com/priceplan/buy/medium"
        },
        {
          title: 'ร้านขนาดใหญ่',
          price: "650",
          isBuyable: true,
          pricePack: [
            '1,800 ฿ / 3 เดือน',
            '6,900 ฿ / 1 ปี',
          ],
          detail: [
            '300 ออเดอร์/เดือน',
            '6 Admin',
            '1 คลังสินค้า',
            '8 ตัวแทนจำหน่าย',
            'ไม่จำกัดรายการสินค้า'
          ],
          url: "https://classic.shipnity.com/priceplan/buy/large"
        },
        {
          title: 'ไม่จำกัด',
          price: "850",
          isBuyable: true,
          isBuyable: true,
          pricePack: [
            '2,300 ฿ / 3 เดือน',
            '8,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            '10 Admin',
            '1 คลังสินค้า',
            '10 ตัวแทนจำหน่าย',
            'ไม่จำกัดรายการสินค้า'
          ],
          url: "https://classic.shipnity.com/priceplan/buy/unlimited"
        },
      ],
      pro: [
        {
          title: 'Quite Pro',
          price: 890,
          isBuyable: true,
          pricePack: [
            '2,600 ฿ / 3 เดือน',
            '8,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            '10 Admin',
            '1 คลังสินค้า',
            '0 ตัวแทนจำหน่าย',
            '<br>-<br>&nbsp',
            'ไม่จำกัดรายการสินค้า',
            'เชื่อมต่อ Facebook และ LINE OA',
            '-',
            'เชื่อมต่อ Marketplace อย่างละ 1 ร้าน'
          ],
          url: "https://www.shipnity.com/priceplan/buy/quite"
        },
        {
          title: 'Pro',
          price: "1,190",
          isBuyable: true,
          pricePack: [
            '3,400 ฿ / 3 เดือน',
            '11,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            '15 Admin',
            '2 คลังสินค้า',
            '15 ตัวแทนจำหน่าย',
            '<br>-<br>&nbsp',
            'ไม่จำกัดรายการสินค้า',
            'เชื่อมต่อ Facebook และ LINE OA',
            'Sale Page / Website สำเร็จรูป',
            'เชื่อมต่อ Marketplace อย่างละ 2 ร้าน'
          ],
          url: "https://www.shipnity.com/priceplan/buy/pro"
        },
        {
          title: 'Truly Pro',
          price: "1,590",
          isBuyable: true,
          pricePack: [
            '4,600 ฿ / 3 เดือน',
            '15,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            '20 Admin',
            '3 คลังสินค้า',
            '30 ตัวแทนจำหน่าย',
            'ระบบ Live สดขายของ <br>(Server รวม<span style="color: #F14D4D">*</span>)<br><span style="font-size: 10px; color: #F14D4D">*ไม่เกิน 3,000 Comments/Live</span>',
            'ไม่จำกัดรายการสินค้า',
            'เชื่อมต่อ Facebook และ LINE OA',
            'Sale Page / Website สำเร็จรูป',
            'เชื่อมต่อ Marketplace อย่างละ 3 ร้าน'
          ],
          url: "https://www.shipnity.com/priceplan/buy/truly"
        },
        {
          title: 'Extremely Pro',
          price: "1,990",
          isBuyable: true,
          pricePack: [
            '5,800 ฿ / 3 เดือน',
            '19,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            'ไม่จำกัด Admin',
            'ไม่จำกัดคลังสินค้า',
            'ไม่จำกัดตัวแทนจำหน่าย',
            'ระบบ Live สดขายของ <br>(Server รวม<span style="color: #F14D4D">*</span>)<br><span style="font-size: 10px; color: #F14D4D">*ไม่เกิน 5,000 Comments/Live</span>',
            'ไม่จำกัดรายการสินค้า',
            'เชื่อมต่อ Facebook และ LINE OA',
            'Sale Page / Website สำเร็จรูป',
            'เชื่อมต่อ Marketplace ไม่จำกัดร้าน'
          ],
          url: "https://www.shipnity.com/priceplan/buy/truly"
        },
        {
          title: 'Private Live',
          price: "4,990",
          isBuyable: true,
          pricePack: [
            '13,900 ฿ / 3 เดือน',
            '49,900 ฿ / 1 ปี',
          ],
          detail: [
            'ไม่จำกัดออเดอร์/เดือน',
            'ไม่จำกัด Admin',
            'ไม่จำกัดคลังสินค้า',
            'ไม่จำกัดตัวแทนจำหน่าย',
            'ระบบ Live สด Server ส่วนตัว เก็บ Comment เร็วที่สุด <br><span style="font-size: 10px; color: #F14D4D">*ไม่จำกัด Comments</span>',
            'ไม่จำกัดรายการสินค้า',
            'เชื่อมต่อ Facebook และ LINE OA',
            'Sale Page / Website สำเร็จรูป',
            'เชื่อมต่อ Marketplace ไม่จำกัดร้าน'
          ],
          url: "https://www.shipnity.com/priceplan/buy/private"
        }
      ]
    }
  },
  methods: {
    scrollToTop () {
      document.getElementById('top-of-content').scrollIntoView({behavior: "smooth", block: "start", inline: "start"});;
    },
    redirect(item) {
      window.location.href = item.url;
    },
    register(isPro) {
      if (isPro) {
        window.location.href='https://www.shipnity.com/users/sign_up';
      } else {
        window.location.href='https://classic.shipnity.com/users/sign_up';
      }
    }
  }
};
</script>

<style lang="scss">
  .container-res {
    margin: 0!important;
  }

  @media only screen and (max-width: 705px) {
    .container-res {
      display: block;
      text-align: center;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 28px;
  }

  .hidden {
    display: none;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #25A9DC;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #25A9DC;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(71px);
    -ms-transform: translateX(71px);
    transform: translateX(71px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .layout-container {
    background-color: white;
    margin: 20px 0 0 0;
    padding: 24px 0 24px 0;
  }

  .package-title-text {
    min-height: 100px;
    color: #0AA699;
  }

  .package-title-head-pro {
    color: #337AB7;
    font-size: 18px;
    font-weight: bold;
  }

  .package-title-text-pro {
    color: #337AB7;
  }

  .gredient-button {
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    background-color: #0090d9;
    border: none;

    &:hover {
      background-color: #0082d4;
      /* background-color: lighten($color: #25A9DC, $amount: 5); */
    }
    &:active {
      background-color: darken($color: #25A9DC, $amount: 5);
    }
    &:focus {
      background-color: darken($color: #25A9DC, $amount: 0);
    }
  }

  .gredient-button-pro {
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    background-color: #0aa699;
    border: none;

    &:hover {
      background-color: #099a8c;
    }
    &:active {
      background-color: #099a8c;
    }
    &:focus {
      background-color: #099a8c;
    }
  }

  .package-card {
    border: 0.1px #aaa solid;
  }

  .package-container {
    padding: 15px 10px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .package-detail-list {
    margin: 15px 20px 15px 20px;
    border-bottom: 1px solid #ccc;
    color: #0AA699;

    &.pro {
      color: #337AB7;
    }
  }

  .package-buyable-btn-pro {
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    position: relative;
    background-color: Transparent;
    background-repeat:no-repeat;
    font-size: 15px;
    font-weight: bold;
    color: #25A9DC;
    border: #25A9DC 1px solid;
    border-radius: 17px;
    padding: 7px 45px;
    outline: none;
    background: none;
    overflow:hidden;
    z-index: 1;
    cursor: pointer;
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;

    &:hover {
      color: white;
      border: #25A9DC 1px solid;
    }

    &:before {
      content: "";
      position: absolute;
      background: #25A9DC;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      -webkit-transition: top 0.09s ease-in;
    }

    &:hover:before {
      top: 0;
    }
  }

  .package-buyable-btn {
    font-family: 'Prompt', Helvetica, Arial, sans-serif;
    position: relative;
    background-color: Transparent;
    background-repeat:no-repeat;
    font-size: 15px;
    font-weight: bold;
    color: rgb(0, 146, 127);
    border: rgb(0, 146, 127) 1px solid;
    border-radius: 17px;
    padding: 7px 45px;
    outline: none;
    background: none;
    overflow:hidden;
    z-index: 1;
    cursor: pointer;
    transition:         0.08s ease-in;
    -o-transition:      0.08s ease-in;
    -ms-transition:     0.08s ease-in;
    -moz-transition:    0.08s ease-in;
    -webkit-transition: 0.08s ease-in;

    &:hover {
      color: white;
      border: rgb(0, 182, 158) 1px solid;
    }

    &:before {
      content: "";
      position: absolute;
      background: rgb(0, 182, 158);
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      -webkit-transition: top 0.09s ease-in;
    }

    &:hover:before {
      top: 0;
    }
  }

  .text-isPro {
    margin: 0 20px;
  }

  .round-price-pack {
    font-size: 12px;
    color: white;
    background-color: #0AA699;
    padding: 5px 10px;
    margin: 0 8px;
    border-radius: 17px;
    white-space: nowrap;

    &.pro {
      background: #25A9DC;
    }
  }

  @media only screen and (max-width: 1390px) and (min-width: 970px) {
    .round-price-pack {
      font-size: 12px;
      color: white;
      background-color: #0AA699;
      padding: 5px 10px;
      margin: 0 8px;
      border-radius: 17px;
      white-space: nowrap;

      &.pro {
        background: #25A9DC;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
</style>
